/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import YouTube from "react-youtube-embed";

const Product = ({ data }) => {
  const product = data.sanityProduct;
  const mainimage = getImage(product.main_image.asset);
  const bgImage = convertToBgImage(mainimage);
  let imagesLength = 0;
  if (product.Images) {
    imagesLength = product.Images.length;
  }
  let embedCount = "embed-odd";
  if (imagesLength % 2 == 0) {
    console.log("The number is even.");
    embedCount = "embed-even";
  }
  const ProductImage = (image) => {
    const productImage = getImage(image.image.asset);
    return (
      <GatsbyImage
        sx={{
          height: "auto",
        }}
        image={productImage}
      />
    );
  };
  return (
    <Layout type="product-outer">
      <SEO
        title={product.headline}
        lang="en"
        description={product._rawIntroText}
        category={product._rawCategory}
      ></SEO>
      <div className="product-top">
        {product.main_image && (
          <div
            sx={{
              position: "relative",
            }}
          >
            <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
              <Container>
                <div
                  sx={{
                    position: "relative",
                    zIndex: "2",
                    padding: "180px 0px 100px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {product.headline && (
                    <h1 sx={{ color: "white" }}>{product.headline}</h1>
                  )}
                </div>
              </Container>

              <div
                sx={{
                  zIndex: "1",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "100%",
                  width: "100%",
                  opacity: "0.7",
                  background:
                    "linear-gradient(to top, #0b5d57 0%, #000000 98%, #000000 100%)",
                }}
              ></div>
            </BackgroundImage>
          </div>
        )}
      </div>

      <Container>
        <div
          className="product-hero"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "80px",
          }}
        >
          <div
            sx={{
              width: ["100%", "40%", "40%"],
            }}
          >
            {product.main_image && (
              <div className="product-full-main-image">
                <GatsbyImage
                  sx={{
                    borderRadius: "8px",
                  }}
                  image={product.main_image.asset.gatsbyImageData}
                />
              </div>
            )}
          </div>
          <div
            sx={{
              width: ["100%", "calc(60% - 40px)", "calc(60% - 40px)"],
              marginLeft: "40px",
            }}
          >
            {product.headline && (
              <h2 sx={{ marginBottom: "0px" }}>{product.headline}</h2>
            )}
            {product._rawIntroText && (
              <div
                sx={{
                  fontSize: "1.3em !important",
                  fontWeight: "400 !important",
                }}
              >
                <PortableText
                  value={product._rawIntroText}
                  components={PortableTextComponent}
                />
              </div>
            )}
            {product.link && (
              <div>
                <a
                  sx={{
                    marginTop: "10px",
                    variant: "buttons.primarySolid",
                  }}
                  href={product.link.url}
                  target="_blank"
                >
                  Shop Now
                </a>
                <a
                  sx={{
                    marginTop: "10px",
                    marginLeft: "20px",
                    variant: "buttons.primaryLight",
                  }}
                  href={"/resources/" + product.slug.current}
                >
                  Resources
                </a>
              </div>
            )}
          </div>
          {product._rawBody && (
            <div
              sx={{
                margin: "80px 0px 0px 0px",
                padding: "50px 0px 0px 0px",
                borderTop: "thin solid rgba(83, 82, 83, 0.15)",
                fontSize: "1.3em !important",
                fontWeight: "400 !important",
              }}
            >
              <PortableText
                value={product._rawBody}
                components={PortableTextComponent}
              />
            </div>
          )}
        </div>
      </Container>
      {product.Images && (
        <div>
          <Container>
            <h2
              sx={{
                textAlign: "center",
                margin: "60px 0px 0px 0px",
                padding: "50px 0px 50px 0px",
                borderTop: "thin solid rgba(83, 82, 83, 0.15)",
              }}
            >
              Gallery
            </h2>
          </Container>
          <ul
            sx={{
              margin: "0px 0px 0px 0px",
              padding: "0px 0px 0px 0px",
              "> li": {
                "&:nth-child(even)": {
                  flexDirection: "row-reverse",
                  backgroundColor: "white",
                },
              },
            }}
          >
            {product.Images.map((image, index) => (
              <li
                key={index}
                sx={{
                  listStyle: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "lightGray",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="product-image-description-image"
                  sx={{
                    width: ["100%", "50%", "50%"],
                  }}
                >
                  {image.image && (
                    <ProductImage image={image.image}></ProductImage>
                  )}
                  {image.youtube_id && (
                    <YouTube
                      id={image.youtube_id}
                      appendSrc="?autoplay=1&mute=1"
                    />
                  )}
                </div>
                <div
                  className="product-image-description-text"
                  sx={{
                    width: ["100%", "50%", "50%"],
                    padding: [
                      "20px 40px 40px 40px",
                      "20px 40px 20px 40px",
                      "20px 40px 20px 40px",
                    ],
                    boxSizing: "border-box",
                    fontSize: "20px",
                    height: "100%",
                  }}
                >
                  {image._rawText && (
                    <PortableText
                      value={image._rawText}
                      components={PortableTextComponent}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {product._rawEmbeds && (
        <div
          className={embedCount}
          sx={{
            "&.embed-odd": {
              ".text-text-container": {
                flexDirection: "row-reverse",
                ".our-embed": {
                  "> div": {
                    margin: "0px !important",
                  },
                },
              },
            },
          }}
        >
          <PortableText
            value={product._rawEmbeds}
            components={PortableTextComponent}
          />
        </div>
      )}
      <Container>
        <div
          sx={{
            padding: "60px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {product.link && (
            <div>
              <a
                sx={{
                  marginTop: "10px",
                  variant: "buttons.primarySolid",
                }}
                href={product.link.url}
                target="_blank"
              >
                Shop Now
              </a>
              <a
                sx={{
                  marginTop: "10px",
                  marginLeft: "20px",
                  variant: "buttons.primaryLight",
                }}
                href={"/resources/" + product.slug.current}
              >
                Resources
              </a>
            </div>
          )}
        </div>
        {product._rawTestimonials && (
          <div
            sx={{
              padding: "60px 0px",
              borderTop: "thin solid lightGray",
              h2: {
                textAlign: "center",
                margin: "0px",
              },
            }}
          >
            <h2>Testimonials</h2>

            <div
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                sx={{
                  maxWidth: "75%",
                  li: {
                    display: "flex",
                    alignItems: "center",
                  },
                  ".carousel .control-next.control-arrow:before": {
                    borderLeft: "28px solid",
                    borderLeftColor: "primary",
                    borderTop: "28px solid transparent",
                    borderBottom: "28px solid transparent",
                  },
                  ".carousel .control-prev.control-arrow:before": {
                    borderRight: "28px solid",
                    borderRightColor: "primary",
                    borderTop: "28px solid transparent",
                    borderBottom: "28px solid transparent",
                  },
                  ".slider": {
                    alignItems: "center",
                    q: {
                      fontStyle: "italic",
                    },
                    "q::before": {
                      fontSize: "100px",
                      color: "primary",
                      height: "100px",
                      display: "block",
                    },
                    "q::after": {
                      fontSize: "100px",
                      color: "primary",
                      // height: "40px",
                      display: "block",
                    },
                  },
                }}
              >
                <Carousel
                  // autoPlay
                  infiniteLoop
                  showArrows
                  showStatus={false}
                  showIndicators={false}
                  transitionTime={3000}
                  interval={10000}
                >
                  {product._rawTestimonials.map((testimonial, index) => (
                    <q
                      key={index}
                      sx={{
                        padding: "0px 60px",
                      }}
                    >
                      <div className="product-testimonial">
                        {testimonial.body && (
                          <PortableText
                            value={testimonial.body}
                            components={PortableTextComponent}
                          />
                        )}
                        <div
                          sx={{
                            fontSize: "14px",
                          }}
                        >
                          {testimonial.name && <div>- {testimonial.name} </div>}
                          {testimonial.title && <div>{testimonial.title} </div>}
                          {testimonial.image && (
                            <img src={testimonial.image?.asset?.url} />
                          )}
                        </div>
                      </div>
                    </q>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        )}
      </Container>
      {product._rawCategory && (
          <Container>
            <div
            sx={{
              padding:"60px 0px",

            }}
            >
          {product._rawCategory.map((category, index) => (
            <div
            key={index}
            sx={{
              fontSize:"10px",
              h3:{
                marginBottom:"0px",
              }
            }}
            >            
            <h3>{category.name}</h3>
            <div>
            <PortableText
              value={category.description}
              components={PortableTextComponent}
            />
            </div>
            </div>
          ))}
          </div>
          </Container>
        )}
    </Layout>
  );
};
export default Product;

export const ArticleQuery = graphql`
  query ProductBySlug($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      headline
      _rawIntroText
      _rawTestimonials(resolveReferences: { maxDepth: 10 })
      _rawEmbeds(resolveReferences: { maxDepth: 10 })
      _rawCategory(resolveReferences: {maxDepth: 10})
      Images {
        youtube_id
        image {
          asset {
            gatsbyImageData(width: 800, height: 600)
          }
        }
        _rawText
      }
      _rawBody
      link {
        url
      }
      main_image {
        asset {
          gatsbyImageData(width: 1500)
        }
      }
    }
  }
`;
